<template>
  <div class="max-w-[96rem] mx-auto">
    <st-box class="mt-40">
      <div class="py-20 text-center">
        <s-text
          as="h3"
          role="title5"
          class="text-title5 leading-title5 font-title5 text-on-surface-elevation-1"
        >
          {{ $t('studio.prj_prod_mngmt.guide1') }}<br />
        </s-text>
        <p class="pt-8 text-text2 leading-text2 text-on-surface-elevation-3">
          {{ $t('studio.prj_prod_mngmt.guide2') }} <br />
          <safe-html tag="span" :html="$t('studio.prj_prod_mngmt.guide3')" /><br />
          {{ $t('studio.prj_prod_mngmt.guide4') }}
        </p>
      </div>
      <div class="relative w-[56rem] h-[35.8rem] mx-auto my-32">
        <img :src="emptyProjectImageSrc" alt="" class="w-full h-full object-contain" />
        <img
          src="@/assets/images/project/project-chart-floating-image.svg"
          alt=""
          class="absolute top-[1.7rem] right-[-6.3rem] w-[16.2rem] h-auto object-contain select-none"
        />
      </div>
    </st-box>
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import * as ProjectImages from '@/assets/images/project/project-chart';
import SafeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';

const { locale } = useI18n();
const emptyProjectImages = ref<Record<string, string>>(ProjectImages);
const emptyProjectImageSrc = computed(
  () => emptyProjectImages.value[`ProjectChartImage_${locale.value.replace('-', '_')}`]
);
</script>
