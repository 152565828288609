<template>
  <div class="mt-8 w-full px-24">
    <template v-if="renderProducts.length > 0 && !isLoading">
      <m-table class="mb-20">
        <colgroup>
          <col style="width: auto" />
          <col style="width: 9.6rem" />
          <col style="width: 12.6rem" />
          <col style="width: 10rem" />
          <col style="width: 8rem" />
          <col style="width: 10rem" />
          <col style="width: 10.6rem" />
          <col style="width: 4rem" />
        </colgroup>
        <thead>
          <tr>
            <st-th
              v-for="(column, colIndex) in columns"
              :key="column.title"
              :sortingUse="!!column.dataIndex"
              :sortingDefault="!!column.dataIndex"
              :thTitle="$t(`${column.title}`)"
              :sortValue="sortList[colIndex]"
              thClass="bg-[#F7FAFD]"
              @onSort="(sorValue) => onSort(sorValue, colIndex, column.dataIndex)"
            />
          </tr>
        </thead>
        <tbody>
          <st-tr
            v-for="(product, productIndex) in renderProducts"
            :key="product.productNo"
            class="hover:bg-interaction-hover cursor-pointer group"
          >
            <template v-if="product.productName">
              <st-td
                class="cursor-pointer"
                aLeft
                posRelative
                @click="redirectToProductDashboard(product.productNo)"
              >
                <st-tooltip-text-over position="bottom-start" :content="product.productName">
                  <strong
                    class="text-md font-bold leading-normal line-clamp-2 group-hover:underline"
                  >
                    {{ product.productName }}
                  </strong>
                </st-tooltip-text-over>
                <product-guide v-if="productIndex === 0 && projectIndex === 0">
                  <s-text as="h4" role="text4" class="font-bold">
                    {{ $t('studio.prj_prod_mngmt.prj_prod_reg_done.release_prep_tooltip_tiltle') }}
                  </s-text>
                  <s-text as="p" role="cap1" class="whitespace-nowrap">
                    <safe-html
                      :html="$t('studio.prj_prod_mngmt.prj_prod_reg_done.release_prep_tooltip_msg')"
                      tag="span"
                    />
                  </s-text>
                </product-guide>
              </st-td>
              <st-td
                aLeft
                class="cursor-pointer"
                :textClass="'text-md font-medium'"
                :tdValue="getProductTypeLabel(product)"
                @click="redirectToProductDashboard(product.productNo)"
              />
              <st-td
                aLeft
                class="cursor-pointer"
                :textClass="'text-md font-medium'"
                @click="redirectToProductDashboard(product.productNo)"
              >
                <safe-html
                  tag="span"
                  :class="product.textClass"
                  :html="$t(product.renderStatus)"
                  class="text-md font-medium"
                />
                <st-tooltip-info
                  :content="
                    $t(
                      getDisplaySanctionText(product.restrictStatus, {
                        isGuideManagementPage: true
                      })
                    )
                  "
                />
                <st-tooltip-info
                  v-if="product.deleteRequestStatus === DELETE_REQUEST_STATUS.REJECT"
                  :content="$t('studio.prj_prod_mngmt.del_req_rejected_tooltip')"
                />
              </st-td>
              <st-td
                aLeft
                class="cursor-pointer"
                :textClass="'text-md font-medium'"
                :tdValue="getProductPrice(product)"
                @click="redirectToProductDashboard(product.productNo)"
              />
              <st-td
                class="cursor-pointer"
                aLeft
                @click="redirectToProductDashboard(product.productNo)"
              >
                <span class="block text-md font-medium">{{
                  product.discountRate ? `${product.discountRate}%` : '-'
                }}</span>
              </st-td>
              <st-td
                class="cursor-pointer"
                aLeft
                @click="redirectToProductDashboard(product.productNo)"
              >
                <span class="block text-md font-medium">
                  {{ getDiscountedPrice(product) }}
                </span>
              </st-td>
              <st-td
                aLeft
                class="cursor-pointer"
                textClass="text-md"
                :tdValue="getDateTimeByLocale(product.createdAt)"
                @click="redirectToProductDashboard(product.productNo)"
              />
              <st-td onlyButton>
                <s-menu-popup
                  :distance="0"
                  :offset="[-12, 16]"
                  placement="left-start"
                  trigger="click"
                  class="inline-block"
                >
                  <template #target>
                    <s-icon
                      size="xl"
                      icon="ic-v2-content-option-vertical-fill"
                      class="text-on-surface-elevation-2"
                    />
                  </template>
                  <template #menuItems>
                    <s-menu-popup-item
                      v-for="opt in product.optionsFilters"
                      :key="opt.value"
                      class="min-w-[15.8rem]"
                      :class="{
                        'text-tint-red-a400':
                          opt.value === 'delete' ||
                          opt.value === 'deleteRequest' ||
                          opt.value === 'cancelDelete',
                        'text-disabled-variant-1 cursor-not-allowed hover:bg-[unset]':
                          product.saleStatus === SALE_STATUS.END && opt.value === 'resumeSale'
                      }"
                      @click="handleAction(product, opt.value)"
                    >
                      {{ $t(opt.label) }}
                    </s-menu-popup-item>
                  </template>
                </s-menu-popup>
              </st-td>
            </template>
            <template v-else>
              <st-td aCenter colspan="8">
                <span
                  class="flex items-center justify-center text-md leading-lg min-h-40 text-shine-effect"
                >
                  {{ $t('studio.pro_prj_mgmt.prod_name_translating_guide') }}
                </span>
              </st-td>
            </template>
          </st-tr>
        </tbody>
      </m-table>
      <s-pagination
        v-model="currentPage"
        :pageCount="Math.ceil(totalElements / PRODUCT_PAGE_SIZE) ?? 0"
        displayFirstAndLastButton
        class="!py-8"
        @change="onPageChange"
      />
    </template>
  </div>
</template>
<script lang="ts" setup>
import { onBeforeUnmount, ref, shallowRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { fetchProductListByProjectApi } from '@/apis/products.api';
import {
  deleteRequestProductApi,
  modifyProductDisplayApi,
  modifyProductSaleStatusApi
} from '@/apis/project-product.api';
import SafeHtml from '@/components/common/safe-html.vue';
import StTooltipInfo from '@/components/common/st-tooltip-info.vue';
import stTooltipTextOver from '@/components/common/st-tooltip-text-over.vue';
import MTable from '@/components/common/table/m-table.vue';
import StTd from '@/components/common/table/st-td.vue';
import StTh from '@/components/common/table/st-th.vue';
import StTr from '@/components/common/table/st-tr.vue';
import ProductGuide from '@/components/product/product-guide.vue';
import DeleteProductDialog from '@/components/project-product/mng/delete-product-dialog.vue';
import ResumeProductDialog from '@/components/project-product/mng/resume-product-dialog.vue';
import SuspendProductDialog from '@/components/project-product/mng/suspend-product-dialog.vue';
import { useApp } from '@/composables/useApp';
import { showAlert, showDialog } from '@/composables/useDialog';
import { SORT_DIRECTION, SORT_VALUE } from '@/constants/common.const';
import { STATUS_CODE } from '@/constants/error.const';
import {
  DELETE_REQUEST_STATUS,
  PLAN_STATUS,
  PRODUCT_TYPE,
  SALE_STATUS,
  VERIFY_STATUS
} from '@/constants/products.const';
import { PRODUCT_HOME_PAGE_URL } from '@/constants/url.const';
import { Confirmation } from '@/enums/common.enum';
import { CurrencyCode } from '@/enums/currency-code.enum';
import { useAppStore } from '@/stores/app.store';
import type { ErrorResponse } from '@/types/common/common.type';
import type { FormOption } from '@/types/common/form.type';
import type { ProductModel } from '@/types/project-product/project-product-model.type';
import type { ProductListByProjectResponse } from '@/types/project-product/project-product-response.type';
import type { Column } from '@/types/table.type';
import { throwContentError } from '@/utils/api-error.util';
import { redirectTo, sortValueToDirection } from '@/utils/common.util';
import { formatPrice } from '@/utils/currency.util';
import { getDateTimeByLocale } from '@/utils/date.util';
import { getDisplaySanctionText } from '@/utils/product.util';
import { capitalizeFirstLetter } from '@/utils/string.util';

type Actions =
  | 'suspendSale'
  | 'resumeSale'
  | 'suspendOpen'
  | 'resumeOpen'
  | 'deleteRequest'
  | 'delete'
  | 'cancelDelete';

interface ProductModelRender extends ProductModel {
  renderStatus: string;
  optionsFilters: FormOption<Actions>[];
  textClass?: string;
}

const { t } = useI18n();
const app = useApp();

const PRODUCT_PAGE_SIZE = 50;

const hasEmptyProductName = ref(false);
const interval = ref<number | null>(null);

const appStore = useAppStore();

const props = defineProps<{ projectId: string; projectName: string; projectIndex: number }>();

const emits = defineEmits<{
  refresh: [];
  changePage: [v: string];
}>();

const columns: Column[] = [
  { title: 'studio.prj_prod_mngmt.prj_prod_reg_done.prj_prod_sort', dataIndex: 'PRODUCT_NAME' },
  { title: 'studio.prj_prod_mngmt.prj_prod_reg_done.prj_prod_type_sort' },
  { title: 'studio.prj_prod_mngmt.prj_prod_reg_done.prj_prod_release_status' },
  { title: 'studio.prj_prod_mngmt.prj_prod_reg_done.prj_prod_price' },
  { title: 'studio.prj_prod_mngmt.prj_prod_reg_done.prj_prod_discount_rate' },
  { title: 'studio.prj_prod_mngmt.prj_prod_reg_done.prj_prod_discount_price' },
  {
    title: 'studio.prj_prod_mngmt.prj_prod_reg_done.prj_prod_create_date',
    dataIndex: 'CREATED_AT'
  },
  { title: '' }
];

const sortList = columns.map(() => SORT_VALUE.NONE);

const renderProducts = ref<ProductModelRender[]>([]);
const totalElements = ref<number>(0);
const isLoading = ref<boolean>(true);
const currentPage = ref<number>(1);
const renderedPage = ref<number>(1);
const moreOptions: FormOption<Actions>[] = [
  { label: 'studio.prj_prod_mngmt.kebap_prod_pg_open_resume_btn', value: 'resumeOpen' },
  { label: 'studio.prj_prod_mngmt.kebap_prod_pg_open_suspend_btn', value: 'suspendOpen' },
  { label: 'studio.prj_prod_mngmt.popup_restart_sale_title', value: 'resumeSale' },
  { label: 'studio.prj_prod_mngmt.kebap_suspend_sale', value: 'suspendSale' },
  { label: 'studio.prj_prod_mngmt.cancel_prod_del_req_btn', value: 'cancelDelete' },
  { label: 'studio.prj_prod_mngmt.kebap_del_request', value: 'deleteRequest' },
  { label: 'studio.prj_prod_mngmt.popup_prod_del_cf_btn', value: 'delete' }
];

const getFilterOptions = (options: Actions[]) => {
  return moreOptions.filter((option: FormOption<Actions>) => options.includes(option.value));
};

const getProductPrice = (product: ProductModelRender) => {
  if (product.paidYn === Confirmation.NO) {
    return t(
      'studio.prj_prod.this_prod.release_details_sales_setting_price_setting_sales_type_this_type_free'
    );
  }
  return product.price > 0 ? `${formatPrice(product.price, CurrencyCode.Usd)} USD` : '-';
};

const getDiscountedPrice = (product: ProductModelRender) => {
  return Number(
    formatPrice(product.price * (1 - (product.discountRate || 0) / 100), CurrencyCode.Usd)
  ) >= 0 &&
    product.price &&
    product.discountRate
    ? `${formatPrice(product.price * (1 - (product.discountRate || 0) / 100), CurrencyCode.Usd)} ${
      CurrencyCode.Usd
    }`
    : '-';
};

const fetchProducts = async (params: {
  sortValue?: number;
  sortKey?: string;
  pageSize?: number;
  showLoading?: boolean;
}) => {
  const { sortValue, sortKey, pageSize } = params;
  const sortDirection = sortValueToDirection(sortValue);
  const request = {
    projectId: props.projectId,
    page: currentPage.value,
    size: pageSize ?? PRODUCT_PAGE_SIZE,
    direction: sortDirection || SORT_DIRECTION.DESC,
    sort: sortValue !== SORT_VALUE.NONE ? sortKey : undefined
  };
  const products = await fetchProductListByProjectApi(request, params.showLoading);

  isLoading.value = false;
  if (products) {
    renderProducts.value = products.contents.map((product: ProductModel) => {
      const { status, optionFilters, textClass } = getProductInfo(product);
      return {
        ...product,
        renderStatus: status,
        optionsFilters: getFilterOptions(optionFilters),
        textClass
      };
    });
    hasEmptyProductName.value = products.contents.some(
      (product: ProductModel) => !product.productName
    );
    totalElements.value = products.totalElements;
  } else {
    hasEmptyProductName.value = false;
  }
};

const refreshProducts = async () => {
  await fetchProducts({ showLoading: false });
};

watch(hasEmptyProductName, async (v: boolean) => {
  if (interval.value) {
    window.clearInterval(interval.value);
  }
  if (v) {
    interval.value = window.setInterval(refreshProducts, 10000);
  }
});

const redirectToProductDashboard = (productNo: number) => {
  redirectTo(PRODUCT_HOME_PAGE_URL, { productId: productNo, projectId: props.projectId });
};

const getProductInfo = (
  product: ProductModel
): {
  status: string;
  optionFilters: Actions[];
  textClass?: string;
} => {
  const { planStatus, saleStatus, displayYn, deleteRequestStatus, verifyStatus } = product;
  if (
    deleteRequestStatus === DELETE_REQUEST_STATUS.REQUEST ||
    deleteRequestStatus === DELETE_REQUEST_STATUS.START
  ) {
    return {
      status: 'studio.prj_prod.this_prod.stt_del_req_completed',
      optionFilters: ['cancelDelete'],
      textClass: 'text-tint-red-a400'
    };
  }

  if (verifyStatus === VERIFY_STATUS.REJECT) {
    return {
      status: 'studio.prj_prod.status_rejected',
      optionFilters: ['delete'],
      textClass: 'text-tint-red-a400'
    };
  }

  if (
    deleteRequestStatus === null ||
    deleteRequestStatus === DELETE_REQUEST_STATUS.NONE ||
    deleteRequestStatus === DELETE_REQUEST_STATUS.REJECT
  ) {
    switch (true) {
      case planStatus === PLAN_STATUS.READY:
        return {
          status: 'studio.prj_prod.this_prod.stt_product_page_upcoming',
          optionFilters: ['delete']
        };
      case planStatus === PLAN_STATUS.PAGE_OPEN && displayYn === Confirmation.YES:
        return {
          status: 'studio.prj_prod.this_prod.stt_product_page_released',
          optionFilters: ['suspendOpen', 'deleteRequest']
        };

      case planStatus === PLAN_STATUS.PRE_PURCHASE && saleStatus === SALE_STATUS.START:
        if (displayYn === Confirmation.YES) {
          return {
            status: 'studio.prj_prod.this_prod.stt_pre_order_period',
            optionFilters: ['suspendSale', 'suspendOpen', 'deleteRequest']
          };
        }
        return {
          status: 'studio.prj_prod.this_prod.stt_product_page_withdrawn',
          optionFilters: ['suspendSale', 'resumeOpen', 'deleteRequest'],
          textClass: 'text-tint-red-a400'
        };
      case planStatus === PLAN_STATUS.RELEASE && saleStatus === SALE_STATUS.START:
        if (displayYn === Confirmation.YES) {
          return {
            status: 'studio.prj_prod.this_prod.stt_official_released',
            optionFilters: ['suspendSale', 'suspendOpen', 'deleteRequest']
          };
        }
        return {
          status: 'studio.prj_prod.this_prod.stt_product_page_withdrawn',
          optionFilters: ['suspendSale', 'resumeOpen', 'deleteRequest'],
          textClass: 'text-tint-red-a400'
        };
      case planStatus === PLAN_STATUS.RELEASE &&
        (saleStatus === SALE_STATUS.END || saleStatus === SALE_STATUS.STOP):
        if (displayYn === Confirmation.YES) {
          return {
            status: 'studio.prj_prod.this_prod.stt_release_sale_suspended',
            optionFilters: ['resumeSale', 'suspendOpen', 'deleteRequest'],
            textClass: 'text-tint-red-a400'
          };
        }
        return {
          status: 'studio.prj_prod_mngmt.prod_stt_page_and_sale_suspended',
          optionFilters: ['resumeSale', 'resumeOpen', 'deleteRequest'],
          textClass: 'text-tint-red-a400'
        };
      case planStatus === PLAN_STATUS.PRE_PURCHASE &&
        (saleStatus === SALE_STATUS.END || saleStatus === SALE_STATUS.STOP):
        if (displayYn === Confirmation.YES) {
          return {
            status: 'studio.prj_prod.this_prod.stt_release_sale_suspended',
            optionFilters: ['resumeSale', 'suspendOpen', 'deleteRequest'],
            textClass: 'text-tint-red-a400'
          };
        }
        return {
          status: 'studio.prj_prod_mngmt.prod_stt_page_and_sale_suspended',
          optionFilters: ['resumeSale', 'resumeOpen', 'deleteRequest'],
          textClass: 'text-tint-red-a400'
        };
      case planStatus === PLAN_STATUS.NONE:
        if (verifyStatus === VERIFY_STATUS.REQUEST) {
          return {
            status: 'studio.prj_prod.status_review_requested',
            optionFilters: ['delete'],
            textClass: 'text-on-surface-elevation-4'
          };
        }
        if (verifyStatus === VERIFY_STATUS.START) {
          return {
            status: 'studio.prj_prod.status_in_review',
            optionFilters: ['delete'],
            textClass: 'text-on-surface-elevation-4'
          };
        }
        if (verifyStatus === VERIFY_STATUS.REJECT) {
          return {
            status: 'studio.prj_prod.status_rejected',
            optionFilters: ['delete'],
            textClass: 'text-on-surface-elevation-4'
          };
        }
        if (verifyStatus === VERIFY_STATUS.PASS) {
          return {
            status: 'studio.prj_prod.status_review_completed',
            optionFilters: ['delete'],
            textClass: 'text-on-surface-elevation-4'
          };
        }
        return {
          status: 'studio.prj_prod.this_prod.stt_pre_release',
          optionFilters: ['delete'],
          textClass: 'text-on-surface-elevation-4'
        };
      case saleStatus === SALE_STATUS.STOP && deleteRequestStatus === DELETE_REQUEST_STATUS.NONE:
        return {
          status: 'studio.prj_prod.this_prod.stt_release_sale_suspended',
          optionFilters: ['resumeSale', 'deleteRequest'],
          textClass: 'text-tint-red-a400'
        };
      case displayYn === Confirmation.NO:
        return {
          status: 'studio.prj_prod.this_prod.stt_product_page_withdrawn',
          optionFilters: ['resumeOpen', 'deleteRequest'],
          textClass: 'text-tint-red-a400'
        };
      default:
        return {
          status: 'studio.prj_prod.this_prod.stt_pre_release',
          optionFilters: ['delete'],
          textClass: 'text-on-surface-elevation-4'
        };
    }
  }

  return {
    status: '-',
    optionFilters: ['delete']
  };
};

const getProductTypeLabel = (product: ProductModel) => {
  return `${capitalizeFirstLetter(product.productType)}${
    product.productDetailType === PRODUCT_TYPE.DLC ? ` ${product.productDetailType}` : ''
  }${product.demoYn === Confirmation.YES ? ` ${PRODUCT_TYPE.DEMO}` : ''}`;
};

const init = async () => {
  await fetchProducts({
    sortKey: 'CREATED_AT',
    sortValue: SORT_VALUE.DESC
  });
};

const onPageChange = async (page: number) => {
  if (page === renderedPage.value) {
    return;
  }

  renderedPage.value = page;

  const products: ProductListByProjectResponse | undefined = await fetchProductListByProjectApi({
    projectId: props.projectId,
    page,
    size: PRODUCT_PAGE_SIZE,
    direction: SORT_DIRECTION.DESC
  });

  if (products) {
    renderProducts.value = products.contents.map((product: ProductModel) => {
      const { status, optionFilters, textClass } = getProductInfo(product);
      return {
        ...product,
        renderStatus: status,
        optionsFilters: getFilterOptions(optionFilters),
        textClass
      };
    });
    totalElements.value = products.totalElements;

    emits('changePage', props.projectId);
  }
};

const deleteProduct = async (product: ProductModel, deleteType: 'normal' | 'request') => {
  if (!(await app.checkMenuWritable())) {
    return;
  }

  if (product.planStatus === PLAN_STATUS.RELEASE && product.saleStatus === SALE_STATUS.START) {
    await showAlert({
      content: `${t('studio.prj_prod_mngmt.del_req_x_on_sale_guide1')}<br/>
       ${t('studio.prj_prod_mngmt.del_req_x_on_sale_guide2')}`,

      confirmLabel: t('studio.common.popup_case_cf_btn'),
      confirmClasses: '!w-full !max-w-full',
      isCancelButtonVisible: false
    });

    return;
  }

  if (
    (product.planStatus === PLAN_STATUS.RELEASE ||
      product.planStatus === PLAN_STATUS.PAGE_OPEN ||
      product.planStatus === PLAN_STATUS.PRE_PURCHASE) &&
    product.displayYn === Confirmation.YES
  ) {
    await showAlert({
      content: [
        t('studio.prj_prod_mngmt.del_req_x_prod_pg_open_guide1'),
        t('studio.prj_prod_mngmt.del_req_x_prod_pg_open_guide2')
      ],
      confirmLabel: t('studio.common.popup_case_cf_btn'),
      confirmClasses: '!w-full !max-w-full',
      isCancelButtonVisible: false
    });

    return;
  }

  const result = await showDialog<boolean>({
    component: shallowRef(DeleteProductDialog),
    props: {
      type: 'product',
      deleteType
    },
    severity: 'info'
  });

  if (result) {
    try {
      const deleteResult = await deleteRequestProductApi({
        productNo: product.productNo,
        deleteRequestYn: Confirmation.YES
      });
      if (deleteResult?.success) {
        if (deleteType === 'normal') {
          await fetchProducts({});
          emits('refresh');
        } else {
          const dialogResult = await showAlert({
            content: t('studio.prj_prod_mngmt.popup_prod_del_req_completed_msg'),
            confirmLabel: t('studio.common.popup_case_cf_btn'),
            confirmClasses: '!w-full !max-w-full'
          });
          if (dialogResult) {
            await fetchProducts({});
            emits('refresh');
          }
        }
        appStore.deleteProductFromSelectedProductListHistory(product.productNo);
      }
    } catch (error) {
      const err = error as ErrorResponse;

      if (err.statusCode === STATUS_CODE.PRODUCT_STATUS_NOT_MODIFIABLE) {
        await showAlert({
          content: [
            t('studio.prj_prod_mngmt.del_req_x_on_sale_guide1'),
            t('studio.prj_prod_mngmt.del_req_x_on_sale_guide2')
          ],
          confirmLabel: t('studio.common.popup_case_cf_btn'),
          confirmClasses: '!w-full !max-w-full',
          isCancelButtonVisible: false
        });
        return;
      }

      if (err.statusCode === STATUS_CODE.PRODUCT_UNDER_COLLECTION_NOT_DELETABLE) {
        await showAlert({
          content: [
            t('studio.prj_prod_mngmt.del_req_x_included_in_collection_guide1'),
            t('studio.prj_prod_mngmt.del_req_x_included_in_collection_guide2')
          ],
          confirmLabel: t('studio.common.popup_case_cf_btn'),
          confirmClasses: '!w-full !max-w-full',
          isCancelButtonVisible: false
        });
        return;
      }
      if (err.statusCode === STATUS_CODE.PRODUCT_CONTAINS_SUB_PRODUCT) {
        await showAlert({
          content: t('studio.prj_prod_mngmt.child_prod_exist_cannot_del'),
          confirmLabel: t('studio.common.popup_case_cf_btn')
        });
      }
    }
  }
};

const suspendProduct = async (product: ProductModel, type: 'sale' | 'open') => {
  if (!(await app.checkMenuWritable())) {
    return;
  }

  const dialog = await showDialog<'sale' | 'open'>({
    component: shallowRef(SuspendProductDialog),
    props: {
      type
    },
    severity: 'info'
  });

  if (dialog === 'sale') {
    try {
      const result = await modifyProductSaleStatusApi({
        productNo: product.productNo,
        saleYn: Confirmation.NO
      });

      if (result?.success) {
        await fetchProducts({});
        emits('refresh');
      }
    } catch (error) {}
  } else {
    try {
      const result = await modifyProductDisplayApi({
        productNo: product.productNo,
        displayYn: Confirmation.NO
      });

      if (result?.success) {
        await fetchProducts({});
        emits('refresh');
      }
    } catch (error) {}
  }
};

const resumeProduct = async (product: ProductModel, type: 'sale' | 'open') => {
  if (product.saleStatus === SALE_STATUS.END && type === 'sale') {
    return;
  }

  if (!(await app.checkMenuWritable())) {
    return;
  }

  const dialog = await showDialog<'sale' | 'open'>({
    component: shallowRef(ResumeProductDialog),
    props: {
      type
    },
    severity: 'info'
  });

  if (dialog === 'sale') {
    try {
      const result = await modifyProductSaleStatusApi({
        productNo: product.productNo,
        saleYn: Confirmation.YES
      });

      if (result?.success) {
        await fetchProducts({});
        emits('refresh');
      }
    } catch (error) {}
  } else {
    try {
      const result = await modifyProductDisplayApi({
        productNo: product.productNo,
        displayYn: Confirmation.YES
      });

      if (result?.success) {
        const dialog = await showAlert({
          content: t('studio.product_page_resumed_msg'),
          confirmLabel: t('studio.common.popup_case_cf_btn'),
          confirmClasses: '!max-w-full !w-full'
        });

        if (dialog) {
          await fetchProducts({});
          emits('refresh');
        }
      }
    } catch (error) {}
  }
};

const cancelDeleteProduct = async (product: ProductModel) => {
  if (!(await app.checkMenuWritable())) {
    return;
  }

  try {
    const result = await deleteRequestProductApi({
      productNo: product.productNo,
      deleteRequestYn: Confirmation.NO
    });

    if (result?.success) {
      await throwContentError(t('studio.prj_prod_mngmt.kebap_del_req_cancelled_msg'));
      await fetchProducts({});
      emits('refresh');
    }
  } catch (error) {}
};

const onSort = async (sortValue: number, colIndex: number, dataIndex?: string) => {
  for (let i = 0; i < sortList.length; i++) {
    if (i !== colIndex) {
      sortList[i] = SORT_VALUE.NONE;
    }
  }
  sortList[colIndex] = sortValue;
  await fetchProducts({ sortValue, sortKey: dataIndex });
};

const handleAction = async (
  product: ProductModel,
  opt:
    | 'suspendSale'
    | 'resumeSale'
    | 'suspendOpen'
    | 'resumeOpen'
    | 'delete'
    | 'cancelDelete'
    | 'deleteRequest'
) => {
  switch (opt) {
    case 'suspendSale':
      await suspendProduct(product, 'sale');
      break;
    case 'resumeSale':
      await resumeProduct(product, 'sale');
      break;
    case 'suspendOpen':
      await suspendProduct(product, 'open');
      break;
    case 'resumeOpen':
      await resumeProduct(product, 'open');
      break;
    case 'delete':
      await deleteProduct(product, 'normal');
      break;
    case 'deleteRequest':
      await deleteProduct(product, 'request');
      break;
    case 'cancelDelete':
      await cancelDeleteProduct(product);
      break;
  }
};

init();

onBeforeUnmount(() => {
  if (interval.value) {
    window.clearInterval(interval.value);
  }
});
</script>
