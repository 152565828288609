<template>
  <div class="mt-[2rem]">
    <st-title :title="$t('studio.prj_prod_mngmt.pg_title')">
      <s-button variant="primary" size="md" @click="registerProject">
        {{ $t('studio.prj_prod_mngmt.btn_prj_reg') }}
      </s-button>
    </st-title>
    <project-product-empty-list v-if="projects.length === 0 && !isLoading && firstEntry" />
    <div v-else-if="!isLoading" class="max-w-[96rem] mx-auto">
      <st-box class="mt-40">
        <div class="flex items-center gap-8">
          <p class="text-2xl font-bold leading-xl text-on-surface-elevation-2">
            {{ $t('studio.prj_prod_mngmt.prj_prod_reg_done.prj_list') }}
          </p>
          <em class="shrink-0 text-3xl font-bold leading-2xl text-brand-primary">
            {{ totalElements }}
          </em>
          <div class="shrink-0 w-260 ml-auto">
            <input-text
              name="searchInput"
              searchable
              :placeholder="$t('studio.prj_prod_mngmt.prj_prod_reg_done.srch_place_holder')"
              :rules="{
                max_length: 100
              }"
              maxLength="100"
              :countable="false"
              @search="handleSearch"
              @clear="handleSearch"
            />
          </div>
        </div>

        <div class="flex gap-8 items-center mt-24 mb-8">
          <s-button variant="outline" size="xs" @click="handleFold(false)">
            {{ $t('studio.prj_prod_mngmt.prj_prod_reg_done.btn_prj_list_collapse') }}
          </s-button>
          <s-button variant="outline" size="xs" @click="handleFold(true)">
            {{ $t('studio.prj_prod_mngmt.prj_prod_reg_done.btn_prj_list_expand') }}
          </s-button>
        </div>
        <div>
          <m-table>
            <colgroup>
              <col style="width: 32.5rem" />
              <col style="width: 11.6rem" />
              <col style="width: 15.4rem" />
              <col style="width: auto" class="col-305-en" />
            </colgroup>
            <thead>
              <tr>
                <st-th
                  v-for="(column, colIndex) in columns"
                  :key="column.title"
                  :sortingUse="!!column.dataIndex"
                  :sortingDefault="!!column.dataIndex"
                  :thTitle="$t(`${column.title}`)"
                  :sortValue="sortList[colIndex]"
                  :tooltipUse="column.title === 'studio.prj_prod_mngmt.prj_prod_reg_done.prj_file'"
                  :isShowIcon="column.title === 'studio.prj_prod_mngmt.prj_prod_reg_done.prj_file'"
                  :tooltipText="$t('studio.prj_prod_mngmt.prj_prod_reg_done.prj_file_tooltip')"
                  :thTitleTooltip="$t(column?.titleTooltip || '')"
                  thClass="bg-[#F7FAFD]"
                  @onSort="(sortValue) => onSort(sortValue, colIndex, column.dataIndex)"
                >
                  <span v-if="colIndex === 3" class="flex gap-4 items-center">
                    {{ $t('studio.prj_prod_mngmt.btn_storage') }}
                    <s-tooltip
                      arrow
                      :content="$t('studio.prj_prod_mngmt.prj_prod_reg_done.prj_file_tooltip')"
                      :duration="0"
                      :distance="4"
                      useFlip
                      flipOnUpdate
                      placement="top"
                      trigger="mouseenter focus"
                      :theme="'studio-tooltip'"
                      :zIndex="2501"
                      :allowHTML="true"
                      :class="'truncate'"
                    >
                      <template #target>
                        <s-icon
                          size="xl"
                          icon="ic-v2-state-help-circle-line"
                          class="text-on-surface-elevation-4 flex"
                        />
                      </template>
                    </s-tooltip>
                  </span>
                </st-th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="projects.length > 0 && !firstEntry">
                <td colspan="4">
                  <div class="flex flex-col gap-8 mt-8">
                    <template v-for="(project, projectIndex) in projects" :key="project.name">
                      <st-folding-project-product
                        :id="project.id"
                        v-model:isOpenDetail="project.expand"
                        projectType
                        :name="project.name"
                        :productCount="`${project.count}`"
                        :creationDate="
                          project.createdAt ? getDateTimeByLocale(project.createdAt) : ''
                        "
                        :showStorageGuide="isAllEmptyProject && projectIndex === 0"
                        isProjectNameFull
                        btnStorage
                        useMoreActions
                        btnCustomerService
                        hasToolTipForName
                        @onStorageBtnClick="showStorageDialog(project.id)"
                        @onEditClick="showEditDialog(project)"
                        @onDeleteClick="showDeleteDialog(project)"
                        @onCustomerServiceBtnClick="handleCreateProduct(project)"
                      >
                        <product-list
                          :projectName="project.name"
                          :projectId="project.id"
                          :projectIndex="projectIndex"
                          @refresh="refreshList"
                          @changePage="handleChangePage"
                        />
                      </st-folding-project-product>
                    </template>
                  </div>
                </td>
              </tr>
              <tr v-else-if="projects.length === 0 && !firstEntry">
                <td colspan="4">
                  <div class="flex justify-center items-center min-h-300">
                    <p class="text-sm leading-md text-placeholder text-center">
                      {{ $t('studio.prj_prod_mngmt.srch_no_result') }}
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </m-table>
        </div>
      </st-box>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useFieldValue, useForm } from 'vee-validate';
import { computed, ref, shallowRef } from 'vue';
import { useI18n } from 'vue-i18n';

import { deleteProjectApi, fetchProjectListApi } from '@/apis/project-product.api';
import StBox from '@/components/common/st-box.vue';
import StTitle from '@/components/common/st-title.vue';
import MTable from '@/components/common/table/m-table.vue';
import StTh from '@/components/common/table/st-th.vue';
import DeleteProductDialog from '@/components/project-product/mng/delete-product-dialog.vue';
import EditNameDialog from '@/components/project-product/mng/edit-project-name.vue';
import ProjectProductEmptyList from '@/components/project-product/mng/empty-list.vue';
import ProductList from '@/components/project-product/mng/product-list.vue';
import StFoldingProjectProduct from '@/components/project-product/st-folding-project-product.vue';
import StorageUpload from '@/components/storage/storage-upload.vue';
import InputText from '@/components/validation/input-text.vue';
import { useApp } from '@/composables/useApp';
import { showAlert, showDialog } from '@/composables/useDialog';
import { SORT_DIRECTION, SORT_VALUE } from '@/constants/common.const';
import { COMMON_ERROR_MESSAGE_KEY, STATUS_CODE } from '@/constants/error.const';
import { PROJECT_PAGE_SIZE } from '@/constants/project-product.const';
import { PRODUCT_REGISTER_PAGE_URL, PROJECT_REGISTER_PAGE_URL } from '@/constants/url.const';
import { projectListMapper } from '@/mappers/project.mapper';
import { useAppStore } from '@/stores/app.store';
import { useUserStore } from '@/stores/user.store';
import type { ErrorResponse } from '@/types/common/common.type';
import type { StorageUploadPopupProps } from '@/types/common/popup.type';
import type { ProjectProductModel } from '@/types/project-product/project-product-model.type';
import type { FetchProjectRequest } from '@/types/project-product/project-product-request.type';
import type { Column } from '@/types/table.type';
import { throwContentError } from '@/utils/api-error.util';
import {
  redirectTo,
  scrollToElementId,
  showCommonErrorDialog,
  sortValueToDirection
} from '@/utils/common.util';
import { getDateTimeByLocale } from '@/utils/date.util';

const app = useApp();
const appStore = useAppStore();
const userStore = useUserStore();
const { selectedGroupId } = storeToRefs(userStore);

const projects = ref<ProjectProductModel[]>([]);
const isLoading = ref<boolean>(true);
const totalElements = ref<number>(0);
const firstEntry = ref<boolean>(true);
const sortDirection = ref<string>();
const searchKeyword = ref<string>();

const isAllEmptyProject = computed(() =>
  projects.value.every((project: ProjectProductModel) => project.count === 0)
);

const columns: Column[] = [
  { title: 'studio.prj_prod_mngmt.prj_prod_reg_done.prj_list_sort', dataIndex: 'PROJECT_NAME' },
  {
    title: 'studio.prj_prod_mngmt.post_prj_reg.prod_num_sort',
    dataIndex: 'PRODUCT_COUNT',
    titleTooltip: 'studio.tooltip.product_count'
  },
  { title: 'studio.prj_prod_mngmt.prj_prod_reg_done.create_date', dataIndex: 'CREATED_AT' },
  { title: '' }
];

const sortList = columns.map(() => SORT_VALUE.NONE);

const fetchProjects = async (
  params: { sortValue?: number; sortKey?: string },
  isSearch: boolean = false,
  isDelete: boolean = false
) => {
  const { sortValue, sortKey } = params;

  const request: FetchProjectRequest = {
    groupId: selectedGroupId.value,
    page: 1,
    size: PROJECT_PAGE_SIZE,
    direction: sortDirection.value ?? SORT_DIRECTION.DESC,
    sort: sortValue !== SORT_VALUE.NONE ? sortKey : 'CREATED_AT',
    q: searchKeyword.value
  };

  const result = await fetchProjectListApi(request);
  isLoading.value = false;

  if (result) {
    const data = projectListMapper(result);

    totalElements.value = data.totalElements;
    projects.value = data.contents;

    if (projects.value.length > 0) {
      projects.value[0].expand = !isSearch;
      firstEntry.value = false;

      if (isSearch) {
        for (let i = 1; i < projects.value.length; i++) {
          projects.value[i].expand = false;
        }
      }
    } else if (isDelete) {
      firstEntry.value = true;
    }
  }
};

const registerProject = () => {
  redirectTo(PROJECT_REGISTER_PAGE_URL);
};

const init = async () => {
  await fetchProjects({
    sortKey: 'CREATED_AT',
    sortValue: SORT_VALUE.DESC
  });
};

const { t } = useI18n();

useForm({
  initialValues: {
    searchInput: ''
  }
});

const searchInput = useFieldValue<string>('searchInput');

const showStorageDialog = async (projectId: string) => {
  const props: StorageUploadPopupProps = {
    projectId,
    isAttachedFile: false
  };

  await showDialog({
    component: shallowRef(StorageUpload),
    props: {
      ...props
    }
  });
};

const showDeleteDialog = async (project: ProjectProductModel) => {
  if (!(await app.checkMenuWritable())) {
    return;
  }
  const result = await showDialog<boolean>({
    component: shallowRef(DeleteProductDialog),
    props: {
      type: 'project'
    },
    severity: 'info'
  });

  if (result) {
    try {
      const deleteResult = await deleteProjectApi(project.id);

      if (deleteResult?.success) {
        await showAlert({
          content: t('studio.common.popup_case_f_complete_del'),
          confirmLabel: t('studio.common.popup_case_cf_btn'),
          confirmClasses: '!w-full !max-w-full'
        });

        refreshList(true);
        appStore.fetchSelectedProductListHistory(selectedGroupId.value);
      }
    } catch (err: unknown) {
      const error = err as ErrorResponse;
      if (
        error.statusCode === STATUS_CODE.PROJECT_CONTAINS_RELEASE_PRODUCT ||
        error.statusCode === STATUS_CODE.PROJECT_CONTAINS_RELEASED_PRODUCT
      ) {
        throwContentError(
          `${t('studio.prj_prod_mngmt.del_req_x_on_sale_guide1')}<br/>${t(
            'studio.prj_prod_mngmt.del_req_x_on_sale_guide2'
          )}`
        );
      } else if (error.statusCode !== STATUS_CODE.GROUP_MIGRATION_ALREADY_PROGRESS &&
                error.statusCode !== STATUS_CODE.PROJECT_MIGRATION_ALREADY_PROGRESS) {
        await showCommonErrorDialog(COMMON_ERROR_MESSAGE_KEY);
      }
    }
  }
};

const showEditDialog = async (project: ProjectProductModel) => {
  if (!(await app.checkMenuWritable())) {
    return;
  }
  const result = await showDialog<boolean>({
    component: shallowRef(EditNameDialog),
    props: {
      project
    },
    severity: 'info'
  });

  if (result) {
    refreshList();
  }
};

const handleFold = (value: boolean) => {
  projects.value.forEach((project: ProjectProductModel) => {
    project.expand = value;
  });
};

const refreshList = (isDelete?: boolean) => {
  fetchProjects({
    sortKey: 'CREATED_AT',
    sortValue: SORT_VALUE.DESC
  }, false, isDelete);
};

const handleSearch = () => {
  searchKeyword.value = searchInput.value;

  if (searchInput.value) {
    fetchProjects({}, true);
  } else {
    fetchProjects({
      sortKey: 'CREATED_AT',
      sortValue: SORT_VALUE.DESC
    });
  }
};

const onSort = (sortValue: number, colIndex: number, dataIndex?: string) => {
  sortDirection.value = sortValueToDirection(sortValue);

  for (let i = 0; i < sortList.length; i++) {
    if (i !== colIndex) {
      sortList[i] = SORT_VALUE.NONE;
    }
  }
  sortList[colIndex] = sortValue;

  fetchProjects({ sortKey: sortDirection.value ? dataIndex : 'CREATED_AT' });
};

const handleChangePage = (projectId: string) => {
  scrollToElementId(projectId);
};

const handleCreateProduct = (project: ProjectProductModel) => {
  redirectTo(`${PRODUCT_REGISTER_PAGE_URL}?projectName=${encodeURIComponent(project.name)}`, {
    projectId: project.id
  });
};

init();
</script>
