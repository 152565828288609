<template>
  <div>
    <s-dialog :open="true" size="md" :to="uuid">
      <s-dialog-overlay />
      <s-dialog-panel>
        <s-dialog-content>
          <s-dialog-content-body>
            <div class="py-24 text-center">
              <s-text
                as="h3"
                role="title5"
                class="text-title5 leading-title5 font-title5 text-on-surface-elevation-1"
              >
                {{ $t('studio.prj_prod_mngmt.prj_edit_guide1') }}
              </s-text>
              <p class="mt-4 text-lg leading-lg text-on-surface-elevation-3">
                {{ $t('studio.prj_prod_mngmt.prj_edit_guide2') }}
              </p>
              <div class="mt-24">
                <input-text
                  :placeholder="$t('studio.prj_prod_mngmt.prj_edit_name_place_holder')"
                  name="projectName"
                  :rules="{
                    required: {
                      value: true,
                      message: $t('studio.prj_prod_mngmt.prj_edit_name_val_msg1')
                    },
                    max_length: 60
                  }"
                  maxLength="60"
                />
              </div>
            </div>
          </s-dialog-content-body>
        </s-dialog-content>
        <s-dialog-footer>
          <s-button class="w-full" variant="outline" @click="closeDialog">
            {{ $t('studio.common.popup_case_cancel_btn') }}
          </s-button>
          <s-button class="w-full" @click="confirm()">
            {{ $t('studio.prj_prod_mngmt.prj_edit_name_cf') }}
          </s-button>
        </s-dialog-footer>
      </s-dialog-panel>
    </s-dialog>

    <s-portal-target :name="uuid" />
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useFieldValue, useForm, useSetFieldError, useSetFieldValue } from 'vee-validate';

import { checkProjectProductName, modifyProjectNameApi } from '@/apis/project-product.api';
import InputText from '@/components/validation/input-text.vue';
import { closeDialog } from '@/composables/useDialog';
import { STATUS_CODE } from '@/constants/error.const';
import { RuleNames } from '@/enums/validation.enum';
import { useUserStore } from '@/stores/user.store';
import type { ErrorResponse } from '@/types/common/common.type';
import type { ProjectProductModel } from '@/types/project-product/project-product-model.type';
import { generateUUID } from '@/utils/uuid.util';
import { generateErrorMsg } from '@/utils/validation.util';

const props = defineProps<{
  hideEdit?: boolean;
  project: ProjectProductModel;
}>();

const { handleSubmit } = useForm({
  initialValues: {
    projectName: ''
  }
});

const userStore = useUserStore();
const { selectedGroupId } = storeToRefs(userStore);

const projectName = useFieldValue<string>('projectName');
const setProjectName = useSetFieldValue('projectName');
const setProjectNameError = useSetFieldError('projectName');

setProjectName(props.project.name);

const uuid: string = `stds-dialog-${generateUUID()}`;

const emit = defineEmits<{ close: [v: boolean] }>();

const confirm = handleSubmit(async () => {
  if (projectName.value.trim() === props.project.name) {
    emit('close', false);
    return;
  }

  try {
    const checkProjectName = await checkProjectProductName({
      groupId: selectedGroupId.value,
      projectName: projectName.value
    });

    if (checkProjectName?.success) {
      const modifyNameResult = await modifyProjectNameApi({
        projectId: props.project.id,
        projectName: projectName.value.trim()
      });
      if (modifyNameResult?.success) {
        emit('close', modifyNameResult.success);
      }
    }
  } catch (err: unknown) {
    const error = err as ErrorResponse;

    if (error.statusCode === STATUS_CODE.PROJECT_NAME_DUPLICATED) {
      setProjectNameError(
        generateErrorMsg('studio.prj_prod_mngmt.prj_edit_name_val_msg3', RuleNames.CHECK_DUPLICATE)
      );
    }
  }
});
</script>
