<template>
  <div>
    <s-dialog :open="true" :to="uuid">
      <s-dialog-overlay />
      <s-dialog-panel class="w-[39.2rem]">
        <s-dialog-content>
          <s-dialog-content-body>
            <s-text
              as="h3"
              role="title6"
              class="text-title6 leading-title6 font-title6 text-on-surface-elevation-1"
            >
              {{
                type === 'project'
                  ? $t('studio.prj_prod_mngmt.popup_prj_del_guide1')
                  : deleteType === 'normal'
                    ? $t('studio.prj_prod_mngmt.popup_prod_del_guide1')
                    : $t('studio.prod_list.kebap_del_request_popup_title')
              }}
            </s-text>
            <s-text as="p" class="mt-4 text-lg leading-text2 text-on-surface-elevation-3 tracking-[-0.45px]">
              <safe-html
                :html="
                  type === 'project'
                    ? $t('studio.prj_prod_mngmt.popup_prj_del_guide2')
                    : deleteType === 'normal'
                      ? `${$t('studio.prj_prod_mngmt.popup_prod_del_guide2')}<br/>${$t(
                        'studio.prj_prod_mngmt.popup_prod_del_guide3'
                      )}`
                      : $t('studio.prod_list.kebap_del_request_popup_msg')
                "
                as="span"
              />
            </s-text>
            <div class="text mt-8">
              <input-text
                name="typingDelete"
                :placeholder="DELETE_TEXT"
                :maxLength="DELETE_TEXT.length.toString()"
                :allowInputMaxLength="false"
                :clearable="false"
                :countable="false"
                inputClass="uppercase"
              />
            </div>
          </s-dialog-content-body>
        </s-dialog-content>
        <s-dialog-footer>
          <s-button variant="outline" @click="closeDialog">
            {{ $t('studio.common.popup_case_cancel_btn') }}
          </s-button>
          <s-button
            variant="red"
            :isDisabled="typingDelete.toUpperCase() !== DELETE_TEXT"
            @click="confirm"
          >
            {{ $t('studio.prj_prod_mngmt.popup_prod_del_cf_btn') }}
          </s-button>
        </s-dialog-footer>
      </s-dialog-panel>
    </s-dialog>

    <s-portal-target :name="uuid" />
  </div>
</template>
<script setup lang="ts">
import { useFieldValue, useForm } from 'vee-validate';
import { toRefs } from 'vue';

import safeHtml from '@/components/common/safe-html.vue';
import InputText from '@/components/validation/input-text.vue';
import { closeDialog } from '@/composables/useDialog';
import { generateUUID } from '@/utils/uuid.util';

const DELETE_TEXT = 'DELETE';

const props = defineProps<{
  type: 'project' | 'product';
  deleteType: 'normal' | 'request';
}>();

const { type } = toRefs(props);

const { handleSubmit } = useForm({
  initialValues: {
    typingDelete: ''
  }
});

const typingDelete = useFieldValue<string>('typingDelete');

const uuid: string = `stds-dialog-${generateUUID()}`;

const emit = defineEmits<{ close: [v: boolean] }>();

const confirm = handleSubmit(() => {
  if (typingDelete.value.toUpperCase() === DELETE_TEXT) {
    emit('close', true);
  }
});
</script>
