<!-- FoldingComponent.vue -->
<template>
  <div
    :id="($attrs.id || '').toString()"
    class="flex rounded-2xl items-center"
    :class="{
      'bg-neutral-variant-2': projectType || termsType,
      'bg-[var(--stds-glob-color-gray20)]': !noBackground && !projectType && !termsType,
      'py-16': !noBackground,
      'py-4': noBackground
    }"
  >
    <button
      type="button"
      class="shrink-0 ml-20 h-24"
      :class="{
        'opacity-0 pointer-events-none': isDisabled,
        'ml-20': !noBackground,
        'ml-8': noBackground
      }"
      @click="onOpen"
    >
      <s-icon
        :size="projectType || termsType ? '4xl' : '3xl'"
        icon="ic-v2-control-arrow-right-line"
        class="text-on-surface-elevation-1 flex"
        :class="{ 'rotate-90': isOpenDetail }"
      />
    </button>
    <span
      v-if="termsType"
      class="shrink-0 w-136 px-12 text-md font-medium leading-lg break-all"
      :class="termsVersion === '-' ? 'text-placeholder' : 'text-on-surface-elevation-2'"
    >
      {{ termsVersion }}
    </span>
    <p
      v-else
      class="text-on-surface-elevation-2 break-all pr-12 flex-1"
      :class="{
        'text-xl font-bold leading-xl pl-12': projectType,
        'text-md font-bold leading-lg pl-8': roleType && noBackground,
        'text-lg font-medium leading-lg pl-8': [roleType, roleAdminType] && !noBackground
      }"
    >
      <st-tooltip-text-over v-if="hasToolTipForName" position="bottom-start" :content="name ?? ''">
        <span class="break-all text-left line-clamp-1">{{ name }}</span>
      </st-tooltip-text-over>
      <template v-else>{{ name }}</template>
      <span v-if="projectSubDescription" class="block text-xs leading-xs text-placeholder">{{
        projectSubDescription
      }}</span>
    </p>
    <template v-if="projectType">
      <span
        v-if="projectCount && projectCount.length > 0"
        class="w-[16.2rem] pl-12 text-md leading-lg text-on-surface-elevation-2 font-medium"
        :class="{ 'flex-1': isFull }"
      >
        {{ projectCount }}
      </span>
      <span
        v-if="memberCount && memberCount.length > 0"
        class="w-[20rem] pl-12 text-md leading-lg text-on-surface-elevation-2 font-medium"
      >
        {{ memberCount }}
      </span>
      <span
        v-if="productCount && productCount.length > 0"
        class="shrink-0 w-116 pl-12 text-md leading-lg text-on-surface-elevation-2 font-medium"
      >
        {{ productCount }}
      </span>
      <span
        v-if="creationDate && creationDate.length > 0"
        class="shrink-0 w-[15.4rem] pl-12 text-md leading-lg text-on-surface-elevation-2 font-regular"
      >
        {{ creationDate }}
      </span>
      <div class="flex shrink-0 gap-8 items-center justify-start w-[30.5rem] pl-12">
        <div class="flex gap-8 items-center min-w-[18.6rem] flex-auto justify-start">
          <s-button
            v-if="btnStorage"
            size="sm"
            variant="outline"
            icon="ic-v2-object-cabinet-line"
            @click="emits('onStorageBtnClick')"
          >
            {{ $t('studio.prj_prod_mngmt.btn_storage') }}
          </s-button>
          <div class="relative">
            <s-button
              v-if="btnCustomerService"
              size="sm"
              variant="secondary"
              icon="ic-v2-control-add-line"
              @click="emits('onCustomerServiceBtnClick')"
            >
              <span class="text-nowrap">{{
                $t('studio.prj_prod_mngmt.prj_prod_reg_done.btn_prod_reg')
              }}</span>
            </s-button>
            <product-guide
              v-if="showStorageGuide"
              storedName="project-storage-guide-shown"
              bottomEnd
            >
              <s-text as="h4" role="text4" class="font-bold">
                {{ $t('studio.prj_prod_mngmt.product_reg_tooltip_title') }}
              </s-text>
              <s-text as="p" role="cap1" class="whitespace-nowrap">
                {{ $t('studio.prj_prod_mngmt.product_reg_tooltip_msg1') }}<br />{{
                  $t('studio.prj_prod_mngmt.product_reg_tooltip_msg2')
                }}
              </s-text>
            </product-guide>
          </div>
        </div>
        <div v-if="useMoreActions" class="w-48 pr-8">
          <s-menu-popup
            :distance="0"
            :offset="[-12, 16]"
            placement="left-start"
            trigger="click"
            class="flex items-center justify-center w-36 h-36 rounded-full hover:bg-[#E8ECF5]"
          >
            <template #target>
              <s-icon
                size="xl"
                icon="ic-v2-content-option-vertical-fill"
                class="text-on-surface-elevation-2"
              />
            </template>
            <template #menuItems>
              <s-menu-popup-item
                icon="ic-v2-community-pencil-line"
                class="min-w-[15.8rem]"
                value="edit"
                @click="emits('onEditClick')"
              >
                {{ $t('studio.prj_prod_mngmt.btn_prj_edit') }}
              </s-menu-popup-item>
              <s-menu-popup-item
                icon="ic-v2-object-delete-line"
                class="min-w-[15.8rem] text-tint-red-a400"
                value="delete"
                @click="emits('onDeleteClick')"
              >
                {{ $t('studio.prj_prod_mngmt.btn_prj_del') }}
              </s-menu-popup-item>
            </template>
          </s-menu-popup>
        </div>
      </div>
    </template>
    <template v-if="termsType">
      <span
        v-if="termsTitle && termsTitle.length > 0"
        class="flex-1 pl-12 text-md font-medium leading-lg truncate"
        :class="termsVersion === '-' ? 'text-placeholder' : 'text-on-surface-elevation-2'"
      >
        <s-tooltip
          arrow
          :content="termsTitle"
          :duration="0"
          :distance="4"
          useFlip
          flipOnUpdate
          placement="bottom-start"
          trigger="mouseenter focus"
          :theme="'studio-tooltip'"
          :zIndex="2501"
          :allowHTML="true"
        >
          <template #target>
            {{ termsTitle }}
          </template>
        </s-tooltip>
      </span>
      <span
        v-if="termsSort && termsSort.length > 0"
        class="shrink-0 w-280 pl-12 text-md font-medium leading-lg text-on-surface-elevation-2"
      >
        {{ termsSort }}
      </span>
      <span
        v-if="termDate && termDate.length > 0"
        class="shrink-0 w-160 pl-12 text-md font-medium leading-lg text-on-surface-elevation-2"
      >
        {{ termDate }}
      </span>
    </template>
    <template v-if="roleType && !noCheckMark">
      <span class="w-[9.6rem] flex shrink-0 items-center justify-center">
        <s-icon
          icon="ic-v2-control-check-bold-line"
          size="3xl"
          :class="{
            'text-on-surface-elevation-1': viewableCheck,
            'text-disabled-variant-2': !viewableCheck
          }"
          class="flex mx-auto"
        />
      </span>
      <span class="w-[9.6rem] flex shrink-0 items-center justify-center pr-8">
        <s-icon
          icon="ic-v2-control-check-bold-line"
          size="3xl"
          :class="{
            'text-on-surface-elevation-1': editableCheck,
            'text-disabled-variant-2': !editableCheck
          }"
          class="flex mx-auto"
        />
      </span>
    </template>
    <template v-if="roleAdminType">
      <template v-if="isEditable">
        <span class="w-[9.6rem] flex shrink-0 items-center justify-center">
          <div class="h-16">
            <checkbox
              v-model="viewableCheck"
              :options="{
                size: 'sm',
                align: 'middle'
              }"
              :disabled="isViewableDisabled"
              @update:modelValue="emits('onViewableCheck', $event)"
            />
          </div>
        </span>
        <span class="w-[9.6rem] flex shrink-0 items-center justify-center">
          <div class="h-16">
            <checkbox
              v-model="editableCheck"
              :options="{
                size: 'sm',
                align: 'middle'
              }"
              :disabled="isEditableDisabled"
              @update:modelValue="emits('onEditableCheck', $event)"
            />
          </div>
        </span>
      </template>
      <template v-else>
        <span class="w-[9.6rem] flex shrink-0 items-center justify-center">
          <s-icon
            icon="ic-v2-control-check-bold-line"
            size="3xl"
            :class="{
              'text-on-surface-elevation-1': viewableCheck,
              'text-disabled-variant-2': !viewableCheck
            }"
            class="flex"
          />
        </span>
        <span class="w-[9.6rem] flex shrink-0 items-center justify-center pr-8">
          <s-icon
            icon="ic-v2-control-check-bold-line"
            size="3xl"
            :class="{
              'text-on-surface-elevation-1': editableCheck,
              'text-disabled-variant-2': !editableCheck
            }"
            class="flex"
          />
        </span>
      </template>
    </template>
  </div>
  <div v-if="isOpenDetail">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { defineModel, toRefs } from 'vue';

import StTooltipTextOver from '@/components/common/st-tooltip-text-over.vue';
import ProductGuide from '@/components/product/product-guide.vue';
import Checkbox from '@/components/validation/checkbox.vue';

const props = defineProps<{
  name?: string; // projectName
  productCount?: string;
  projectCount?: string;
  projectType?: boolean;
  roleType?: boolean;
  memberCount?: string;
  creationDate?: string;
  roleAdminType?: boolean;
  termsVersion?: string;
  termsTitle?: string;
  termsSort?: string;
  termsType?: boolean;
  viewableCheck?: boolean;
  editableCheck?: boolean;
  isEditable?: boolean;
  isDisabled?: boolean;
  btnStorage?: boolean;
  isFull?: boolean;
  useMoreActions?: boolean;
  termDate?: string;
  isProjectNameFull?: boolean;
  noCheckMark?: boolean;
  noBackground?: boolean;
  projectSubDescription?: string;
  btnCustomerService?: boolean;
  showStorageGuide?: boolean;
  hasToolTipForName?: boolean;
}>();

const emits = defineEmits<{
  onFold: [v: boolean];
  onViewableCheck: [v: boolean];
  onEditableCheck: [v: boolean];
  onStorageBtnClick: [];
  onEditClick: [];
  onDeleteClick: [];
  onCustomerServiceBtnClick: [];
}>();

const { viewableCheck, editableCheck } = toRefs(props);

const isOpenDetail = defineModel<boolean>('isOpenDetail');
const isViewableDisabled = defineModel<boolean>('isViewableDisabled');
const isEditableDisabled = defineModel<boolean>('isEditableDisabled');

const onOpen = () => {
  isOpenDetail.value = !isOpenDetail.value;
  emits('onFold', !isOpenDetail.value);
};
</script>
